import React from 'react'
import {Stack, Main, Layout} from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'

const Contact =  (props) => (
    <Layout {...props}>
        <Seo title='Contact'/>
        <Divider/>
        <Stack>
            <Main>
                <PageTitle
                    header="Let's Connect"
                    subheader="If you wish to get in touch for any reason, please fill the form below, and we will try to get back to you as soon as possible."
                />
                <Divider/>
                <ContactForm/>
            </Main>
        </Stack>
    </Layout>
)

export default Contact;